<template>
    <div class="sign-here">
        <div @click="onClickSign" class="btn sign-btn" :class="{ focus: idx === signStatus.nowIdx + 1 }" v-if="showBtn">
            <img class="m-r-3" :src="require('@/assets/images/icons/edit.png')" width="16px" height="16px" alt="" />
            <span class="f-14">서명 입력</span>
        </div>
        <div class="signed" v-else>
            <span>(인 또는 서명)</span>
            <img v-if="sign" :src="sign" width="80px" height="80px" alt="" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SignComponent',
    props: {
        sign: String,
        idx: Number,
        signStatus: Object,
    },
    computed: {
        contents() {
            return []
        },
        showBtn() {
            if (this.signStatus.mode === 'import') return false

            return !this.sign ? true : this.signStatus.clicked[this.idx - 1] === false
        },
    },
    methods: {
        async onClickSign() {
            if (!this.sign) {
                const { dataUrl, imgBlob } = await this.$modal.custom({
                    component: 'ModalSignPad',
                    options: { preventCloseOnMousedownMask: true },
                })
                if (dataUrl && imgBlob) {
                    this.$bus.$emit('getUserSign', { dataUrl, imgBlob })
                    this.$set(
                        this.signStatus,
                        'clicked',
                        this.signStatus.clicked.map((c, i) => (this.idx - 1 === i ? true : c))
                    )
                    this.$set(
                        this.signStatus,
                        'nowIdx',
                        this.signStatus.clicked.findIndex(e => e === false)
                    )
                }
                return
            }
            this.$set(
                this.signStatus,
                'clicked',
                this.signStatus.clicked.map((c, i) => (this.idx - 1 === i ? true : c))
            )
            this.$set(
                this.signStatus,
                'nowIdx',
                this.signStatus.clicked.findIndex(e => e === false)
            )
        },
    },
}
</script>

<style scoped lang="scss">
.sign-here {
    margin-left: 16px;

    .signed {
        position: relative;
        height: 32px;
        width: 96px;
        @include center;

        span {
            color: $grey-05;
            font-size: 13px;
        }

        img {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .sign-btn {
        padding: 3px 8px !important;
        width: fit-content !important;
        height: 32px !important;
        background: #f1fffc;
        border: 1px solid $grey-07;
        @include spoqa-f-regular;
        @include center;

        &.focus {
            box-shadow: 0 0 8px #04c9b2;
            border: 1px solid #04c9b2;
        }
    }
}
</style>
